import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import styles from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';
import BookButton from './BookButton/BookButton';
import { Section, SectionTypes } from '../types';

interface ControllerProps {
  greetingsText: string;
  contentWidth: number;
  displayOptions: {
    header: boolean;
    sidebar: boolean;
  };
  sections: Section[];
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

const getSectionByType = (type: SectionTypes) => {
  switch (type) {
    case SectionTypes.TITLE_TAGLINE:
      return <TitleAndTagline />;
    case SectionTypes.SCHEDULING:
      return <div>scheduling</div>;
    case SectionTypes.POLICY:
      return <div>policy</div>;
    case SectionTypes.DETAILS:
      return <div>details</div>;
    case SectionTypes.DESCRIPTION:
      return <div>description</div>;
    case SectionTypes.CONTACT:
      return <div>contact</div>;
    default:
      return null;
  }
};

export default translate()(
  withExperiments<WidgetProps>(
    ({
      t,
      experiments,
      greetingsText,
      contentWidth,
      displayOptions,
      sections,
      ...rest
    }) => {
      return (
        <div
          {...styles('root', {}, rest)}
          data-hook="booking-service-page-wrapper"
        >
          {displayOptions.header ? (
            <div
              data-hook="booking-service-page-header"
              className={styles.header}
            >
              Hello I'm The Header with greeting from serverless {greetingsText}
              <BookButton onClick={console.log} />
            </div>
          ) : null}
          <div className={styles.dynamicWrapper}>
            <div
              className={styles.dynamicItem}
              style={{ flexBasis: `${contentWidth}%` }}
            >
              {sections.map((section) =>
                section.visible ? getSectionByType(section.type) : null,
              )}
            </div>
            {displayOptions.sidebar ? (
              <div
                className={styles.dynamicItem}
                style={{ flexBasis: `${100 - contentWidth}%` }}
              >
                Hello I'm The Sidebar
              </div>
            ) : null}
          </div>
        </div>
      );
    },
  ),
);
