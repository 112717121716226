import React from 'react';
import {
  withExperiments,
  translate,
  InjectedExperimentsProps,
  InjectedTranslateProps,
} from 'yoshi-flow-editor-runtime';
import { Button, PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import styles from './BookButton.st.css';

interface ControllerProps {
  onClick: Function;
}

type BookButtonProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

export default translate()(
  withExperiments<BookButtonProps>(
    ({ t, experiments, onClick, children, ...rest }) => {
      return (
        <Button
          {...styles('root', {}, rest)}
          data-hook="book-button"
          size={SIZE.medium}
          priority={PRIORITY.primary}
          onClick={() => {
            onClick();
          }}
          // fullWidth={isMobile}
          aria-label="Book Now"
          role="button"
          tabIndex={0}
          // upgrade
        >
          {children || t('app.widget.cta.book-now')}
        </Button>
      );
    },
  ),
);
